'use client';

import { routes } from '@constants/routes';
import Image from 'next/image';
import Link from 'next/link';
import { Container, Button } from 'react-bootstrap';

export default function Error({ reset }: { error: Error & { digest?: string }; reset: () => void }) {
    return (
        <section>
            <Container className="text-center pt-5">
                <Image src="/bigbyte-logo-nav.svg" alt="BigByte Digital Logo" width={500} height={500} />
                <h1 className="pt-4">Whoops!</h1>
                <p>An undexpected error has occurred.</p>
                <p>
                    Try starting back at our{' '}
                    <Link href={routes.home}>
                        <Button variant="link">home page</Button>
                    </Link>{' '}
                    or{' '}
                    <Link href={routes.contact}>
                        <Button variant="link">contact us</Button>
                    </Link>{' '}
                    if we can help you find it.
                </p>
                <button onClick={() => reset()}>Click here to head home</button>
            </Container>
        </section>
    );
}
